import {relations} from 'drizzle-orm';
import {
  boolean,
  integer,
  jsonb,
  pgTable,
  primaryKey,
  serial,
  timestamp,
  unique,
  varchar,
} from 'drizzle-orm/pg-core';
import {storeOrders} from './order';
import {stores} from './store';

export const shipments = pgTable(
  'shipments',
  {
    id: serial('id').primaryKey(),
    storeOrderId: integer('store_order_id')
      .references(() => storeOrders.id)
      .notNull(),
    carrierId: integer('carrier_id')
      .references(() => carriers.id)
      .notNull(),
    cost: integer('cost'),
    etaFrom: timestamp('eta_from', {
      withTimezone: true,
    }).notNull(),
    etaTo: timestamp('eta_to', {
      withTimezone: true,
    }).notNull(),
    trackingCode: varchar('tracking_code', {length: 256}),
    comments: varchar('comments', {
      length: 500,
    }),
    shippedAt: timestamp('shipped_at', {
      withTimezone: true,
    }),
    createdAt: timestamp('created_at', {
      withTimezone: true,
    })
      .defaultNow()
      .notNull(),
    updatedAt: timestamp('updated_at', {
      withTimezone: true,
    }).$onUpdateFn(() => new Date()),
  },
  table => [
    unique('uniqueStoreOrderShipment').on(table.storeOrderId, table.carrierId),
  ]
);

export const shipmentsRelations = relations(shipments, ({one}) => ({
  storeOrder: one(storeOrders, {
    fields: [shipments.storeOrderId],
    references: [storeOrders.id],
  }),
  carrier: one(carriers, {
    fields: [shipments.carrierId],
    references: [carriers.id],
  }),
}));

export type Shipment = typeof shipments.$inferSelect;

export interface CarrierQuote {
  max_distance: number;
  price: number;
  eta_from: number;
  eta_to: number;
  weight_price_rate: number;
  same_state?: boolean;
}
export const carriers = pgTable('carriers', {
  id: serial('id').primaryKey(),
  name: varchar('name', {
    length: 256,
  }).notNull(),
  tracking: boolean('tracking').notNull(),
  trackingUrl: varchar('tracking_url', {length: 256}),
  disabled: boolean('disabled').notNull().default(false),
  quotes: jsonb('quotes').$type<CarrierQuote[]>().notNull(),
});

export const carrierRelations = relations(carriers, ({many}) => ({
  carrierStores: many(storeToCarrier),
}));

export type Carrier = typeof carriers.$inferSelect;

export const storeToCarrier = pgTable(
  'store_to_carrier',
  {
    storeId: integer('store_id')
      .notNull()
      .references(() => stores.id),
    carrierId: integer('carrier_id')
      .notNull()
      .references(() => carriers.id),
    duration: integer('duration').default(96),
  },
  t => [
    primaryKey({
      columns: [t.storeId, t.carrierId],
    }),
  ]
);

export const storeToCarrierRelations = relations(storeToCarrier, ({one}) => ({
  store: one(stores, {
    fields: [storeToCarrier.storeId],
    references: [stores.id],
  }),
  carrier: one(carriers, {
    fields: [storeToCarrier.carrierId],
    references: [carriers.id],
  }),
}));
