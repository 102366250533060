import {relations} from 'drizzle-orm';
import {
  bigint,
  boolean,
  integer,
  jsonb,
  pgEnum,
  pgTable,
  real,
  serial,
  timestamp,
  varchar,
} from 'drizzle-orm/pg-core';
import {addHours} from '.';
import {MaxShippingHours} from '../constant';
import {customers} from './customer';
import {payouts} from './finance';
import {penalties} from './operation';
import {Attribute, products, variants} from './product';
import {Carrier, carriers, Shipment, shipments} from './shipping';
import {Store, stores, StoreWithVendors} from './store';

export const checkoutStatus = pgEnum('checkout_status', [
  'ACTIVE',
  'COMPLETED',
  'ABANDONED',
]);

export const paymentStatus = pgEnum('payment_status', [
  'PENDING',
  'SUCCESS',
  'FAILED',
  'CLOSED',
]);

export interface OrdersShippingDetails {
  address: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  state: string;
  city: string;
}

export const storeOrderStatus = pgEnum('store_order_status', [
  'CANCELED_BY_STORE',
  'CANCELED_BY_CUSTOMER',
  'CANCELED_BY_EXPIRATION',
  'DISPUTE',
  'PENDING',
  'SHIPPED',
  'RECEIVED_BY_EXPIRATION',
  'RECEIVED_BY_CUSTOMER',
]);

export type StoreOrderStatus = (typeof storeOrders.$inferSelect)['status'];

export const orders = pgTable('orders', {
  id: serial('id').primaryKey(),
  customerId: integer('customer_id').references(() => customers.id),
  orderID: bigint('orderID', {
    mode: 'number',
  })
    .notNull()
    .$defaultFn(() =>
      Number(
        `${Number(Date.now().toString().slice(1, 5)) - 3200}${
          Number(Date.now().toString().slice(6, 12)) +
          Math.round(Math.random() * 100)
        }${Math.floor(100 + Math.random() * 900)}`
      )
    )
    .unique(),
  deliveryDetails: jsonb('delivery_details')
    .notNull()
    .$type<OrdersShippingDetails>(),
  paymentStatus: paymentStatus('payment_status').notNull().default('PENDING'),
  total: integer('total').notNull(),
  references: varchar('references', {
    length: 256,
  })
    .array()
    .notNull(),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp('updated_at')
    .notNull()
    .defaultNow()
    .$onUpdateFn(() => new Date()),
  //Payment confirmed status success or not
  paymentConfirmedAt: timestamp('payment_confirmed_at'),
  lastRetriedPaymentAt: timestamp('last_retried_payment_at'),
  internal: boolean('internal'),
});

export const orderRelations = relations(orders, ({one, many}) => ({
  storeOrders: many(storeOrders),
  customer: one(customers, {
    fields: [orders.customerId],
    references: [customers.id],
  }),
}));

export type Order = typeof orders.$inferSelect;

export type OrderItem = typeof orderItems.$inferSelect;

export type StoreOrder = typeof storeOrders.$inferSelect;

export type OrderWithItems = typeof orders.$inferSelect & {
  storeOrders: (StoreOrder & {
    items: OrderItem[];
    store: StoreWithVendors;
  })[];
};

export type StoreOrderWithItems = StoreOrder & {
  items: OrderItem[];
  store: Store;
  order: Order;
};

export type StoreOrderWithShipment = StoreOrderWithItems & {
  shipment: (Shipment & {carrier: Carrier}) | null;
  carrier: Carrier;
};

export const storeOrders = pgTable('store_orders', {
  id: serial('id').primaryKey(),
  orderId: integer('order_id')
    .references(() => orders.id)
    .notNull(),
  storeId: integer('store_id')
    .references(() => stores.id)
    .notNull(),
  carrierId: integer('carrier_id')
    .references(() => carriers.id)
    .notNull(),
  payoutId: integer('payout_id').references(() => payouts.id),
  subTotal: integer('sub_total').notNull(),
  shippingCost: integer('shipping_cost').notNull(),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  status: storeOrderStatus('status').notNull().default('PENDING'),
  updatedAt: timestamp('updated_at', {
    withTimezone: true,
    mode: 'date',
  })
    .notNull()
    .defaultNow()
    .$onUpdateFn(() => new Date()),
  deadline: timestamp('deadline', {withTimezone: true})
    .notNull()
    .$defaultFn(() => addHours(new Date(), MaxShippingHours)),
});

export type InsertStoreOrder = typeof storeOrders.$inferInsert;

export const storeOrdersRelations = relations(storeOrders, ({one, many}) => ({
  order: one(orders, {
    fields: [storeOrders.orderId],
    references: [orders.id],
  }),
  store: one(stores, {
    fields: [storeOrders.storeId],
    references: [stores.id],
  }),
  carrier: one(carriers, {
    fields: [storeOrders.carrierId],
    references: [carriers.id],
  }),
  shipment: one(shipments),
  items: many(orderItems),
  payout: one(payouts, {
    fields: [storeOrders.payoutId],
    references: [payouts.id],
  }),
  penalties: many(penalties),
}));

export const orderItems = pgTable('order_items', {
  id: serial('id').primaryKey(),
  storeOrderId: integer('store_order_id')
    .references(() => storeOrders.id)
    .notNull(),
  variantId: integer('variant_id')
    .references(() => variants.id)
    .notNull(),
  productId: integer('product_id')
    .references(() => products.id)
    .notNull(),
  storeId: integer('store_id')
    .references(() => stores.id)
    .notNull(),
  brandName: varchar('brand_name', {
    length: 256,
  }),
  title: varchar('title', {
    length: 256,
  }).notNull(),
  description: varchar('description', {length: 256}).notNull(),
  discountPercentage: real('discount_percentage').notNull(),
  price: integer('price').notNull(),
  quantity: integer('quantity').notNull(),
  image: varchar('image', {
    length: 256,
  }).notNull(),
  attributes: jsonb('attributes').$type<Attribute[]>(),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp('updated_at', {
    withTimezone: true,
    mode: 'date',
  })
    .notNull()
    .defaultNow()
    .$onUpdateFn(() => new Date()),
  commission: integer('commission').notNull(),
});

export type InsertOrderItem = typeof orderItems.$inferInsert;

export const orderItemsRelations = relations(orderItems, ({one}) => ({
  store: one(stores, {
    fields: [orderItems.storeId],
    references: [stores.id],
  }),
  variant: one(variants, {
    fields: [orderItems.variantId],
    references: [variants.id],
  }),
  product: one(products, {
    fields: [orderItems.productId],
    references: [products.id],
  }),
  storeOrder: one(storeOrders, {
    fields: [orderItems.storeOrderId],
    references: [storeOrders.id],
  }),
}));

export interface CheckoutItem {
  quantity: number;
  variantId: number;
  modifiedAt: Date;
}

export const checkouts = pgTable('checkouts', {
  id: serial('id').primaryKey(),
  customerId: integer('customer_id').references(() => customers.id),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp('updated_at', {
    withTimezone: true,
  })
    .notNull()
    .defaultNow()
    .$onUpdateFn(() => new Date()),
  status: checkoutStatus('status').notNull().default('ACTIVE'),
  expiresAt: timestamp('expires_at', {
    withTimezone: true,
  })
    .notNull()
    .defaultNow()
    .$default(() => addHours(new Date(), 7 * 24)),
  storesCarriers: jsonb('stores_carriers')
    .$type<{storeId: number; cid: number}[]>()
    .default([]),
  state: varchar('state', {
    length: 256,
  }),
  city: varchar('city', {
    length: 256,
  }),
  orderId: integer('order_id').references(() => orders.id),
  items: jsonb('checkout_items').$type<CheckoutItem[]>().notNull(),
});

export const checkoutsRelations = relations(checkouts, ({one}) => ({
  customer: one(customers, {
    fields: [checkouts.customerId],
    references: [customers.id],
  }),
  order: one(orders, {
    fields: [checkouts.orderId],
    references: [orders.id],
  }),
}));
